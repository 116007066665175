.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.top {
    flex: 0;
    border-bottom: solid 2px var(--adm-color-border);
    background-color: white;
}

.body {
    flex: 1;
    overflow-y: auto;
}

.bottom {
    flex: 0;
    border-top: solid 2px var(--adm-color-border);
    position: sticky;
    bottom: 0;
    background-color: white;
}

.center {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cardStyleShadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contentCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.backgroundHome {
    background: linear-gradient(180deg, #FD414C 0%,
    rgba(255, 42, 55, 0.836864) 30.21%,
    rgba(255, 90, 100, 0.5625) 55.21%,
    rgba(217, 217, 217, 0) 100%);
    height: 50vh;
}

.number-indicator {
    position: absolute;
    bottom: 12px;
    right: 12px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1px 14px;
    border-radius: 12px;
    font-size: 10px;
}

.page-indicator {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    border-radius: 12px;
}

.backgroundPage {
    background-color: #FD414C;
    height: 12em;
    padding-top: 5px;
}

.backgroundContent {
    background-color: white;
    padding: 1.25rem;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.backgroundAdditional {
    background-color: #FD414C;
    height: 10em;
    padding-top: 5px;
    border-bottom-right-radius: 3em;
    border-bottom-left-radius: 3em;
}

.ant-btn-primary {
    background-color: #0A52BD;
    color: #fff;
    border-color: #0A52BD;
}

/* override antd mobile style */
.adm-tab-bar-item-active {
    color: var(--adm-color-danger) !important;
}

.adm-list-item-content-prefix {
    display: flex;
    align-items: center;
}

.adm-list-item-content-extra {
    font-weight: 500;
    color: var(--adm-color-text) !important;
}

.adm-tab-bar-item-icon {
    font-size: 22px !important;
}

/* override antd style */
.ant-list-item-extra {
    font-weight: 600;
}

.ant-segmented {
    padding: 4px;
}

.ant-upload.ant-upload-select {
    width: 100%;
    border: 2px dashed #d9d9d9;
    border-radius: 8px;
    text-align: center;
    background: rgba(217, 217, 217, 0.2);
}
